import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import Markdown from '../components/Markdown';
import PageTitle from '../components/PageTitle';


const Legal = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const footerData = data.allDatoCmsFooter.edges[0].node;
  const page = data.allDatoCmsLegal.edges[0].node;
  const { body, gradient, seoMetaTags, title } = page;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{title}</PageTitle>
        <Markdown block={{ text: body }} hasBulletStyling={false} hasLinkUnderlines={false} />
      </GradientWrapper>
    </Layout>
  );
};

Legal.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Legal;

export const query = graphql`
  query LegalBySlug($locale: String!, $slug: String!) {
    allDatoCmsLegal(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          slug
          body
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          newsletterSocialDescription
          bookings
          copyrightText
          photographyCopyrightText
          legalLinks {
            title
            slug
          }
        }
      }
    }
  }
`;
