import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { bulletStyles, fontStyles, linkStyles } from '../constants/styles';
import { fonts } from '../constants/typography';

import { customMarkdown } from '../helpers/markdown';


const Wrapper = styled.div`
  margin: 0 auto;
  ${fontStyles.body}

  ${({ hasUnderlineStyles }) => (hasUnderlineStyles
    ? `a { ${linkStyles} }`
    : `a::before {
        content: none;
      }
      a {
        text-decoration: underline;
      }`)}

  blockquote {
    font-style: italic;
    margin: 1.25rem 0; /* 20/16 */
  }

  .fonts-loaded blockquote {
    font-family: ${fonts.fontFamilyItalic};
  }

  img {
    display: block;
    max-width: 100%;
    margin: 1.25rem 0; /* 20/16 */
  }

  ol {
    list-style-type: none;
    text-align: left;
    counter-reset: ordered-list;
  }

  ol > li {
    position: relative;
    padding-left: 1.5em;
  }

  ol > li::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: counter(ordered-list)". ";
    counter-increment: ordered-list;
    text-align: left;
  }

  ul li {
    position: relative;
    padding-left: 1.25em;
    list-style-type: none;

    ${(props) => (props.hasBulletStyling ? bulletStyles : null)}
  }

  li p {
    display: inline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.5em;
  }

  h1 {
    ${fontStyles.title}
    text-align: center;
  }

  h2, h3, h4, h5, h6 {
    ${fontStyles.uppercase}
    ${fontStyles.body}
  }

  /* Spacing */
  * + * {
    margin-top: 1em; /* Generic spacing rule */
  }

  * + p {
    margin-top: 1.5em;
  }

  * + li {
    margin-top: 0;
  }

  * + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
    margin-top: 2.5em;
  }
`;

const Markdown = ({ block, hasBulletStyling, hasLinkUnderlines }) => (
  <Wrapper hasBulletStyling={hasBulletStyling} hasLinkUnderlines={hasLinkUnderlines}>{customMarkdown(block.text)}</Wrapper>
);


Markdown.propTypes = {
  block: PropTypes.object.isRequired,
  hasBulletStyling: PropTypes.bool,
  hasLinkUnderlines: PropTypes.bool,
};

Markdown.defaultProps = {
  hasBulletStyling: true,
  hasLinkUnderlines: true,
};

export default Markdown;
